import React from "react"

const Container = ({ children, className }) => {
  return (
    <div
      className={`relative h-full w-full p-6 md:p-10 lg:pl-32 ${
        className ?? ""
      }`}
    >
      {children}
    </div>
  )
}

export default Container
