import { useEffect } from "react"

const TransitionWrapper = ({
  page,
  children,
  isLoaded,
  transitionStatus,
  onEntering,
  onEntered,
  onExiting,
  onExited,
}) => {
  useEffect(() => {
    setAnimation(transitionStatus)
  }, [isLoaded, transitionStatus])

  const setAnimation = transitionStatus => {
    // console.log(`${page.toUpperCase()} ${transitionStatus.toUpperCase()}`)

    switch (transitionStatus) {
      case "exiting":
        typeof onExiting == "function" && onExiting()
        break
      case "exited":
        typeof onExited == "function" && onExited()
        break
      case "entering":
        typeof onEntering == "function" && onEntering()
        break
      case "entered":
        typeof onEntered == "function" && onEntered()
        break
      default:
        break
    }
  }

  return children
}

export default TransitionWrapper
